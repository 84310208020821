import React, { ReactNode } from 'react'
import cx from 'classnames'
import { Menu } from '@headlessui/react'

import { Text } from 'components/dataDisplay'

import Item, { ItemProps } from './components/Item/Item'

import s from './DropdownMenu.scss'


export type DropdownMenuProps = {
  className?: string
  itemsClassName?: string
  buttonClassName?: string
  children?: React.ReactElement<{ isOpen: boolean }>
  items?: Omit<ItemProps, 'style'>[]
  itemsNode?: ReactNode
  sections?: Header.NavigationItem['sections']
  style?: ItemProps['style']
  placement?: 'bottomLeft' | 'bottomRight' | 'left' | 'right'
  disabled?: boolean
  onOpen?: (isOpen: boolean) => void
}

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  const { children, className, itemsClassName, buttonClassName, items, itemsNode, sections, placement = 'left', style, disabled, onOpen } = props

  if (!React.isValidElement(children)) {
    throw new Error('DropdownMenu children should be a single valid element')
  }

  const rootClassName = cx('relative', className, { 'opacity-32': disabled })
  const menuClassName = cx('block py-12px bg-white radius-8 shadow-titanic-1', s.menu, itemsClassName, {
    [s[placement]]: placement,
  })

  const ItemsNode = () => {
    if (sections) {
      return (
        <div className="flex">
          {
            sections.map(({ title: sectionTitle, id: sectionId }, index) => {
              const sectionItems = items.filter(((item) => item?.sectionId === sectionId))

              return Boolean(sectionItems?.length) && (
                <div className={cx({ 'ml-40px': index })} key={sectionId}>
                  <Text
                    className="mt-24px ml-20px mb-12px opacity-48"
                    message={sectionTitle}
                    size="s13-r"
                  />
                  {
                    sectionItems.map((item, index) => {
                      return Boolean(item) && (
                        <Item
                          className="select-none"
                          key={index}
                          style={style}
                          {...item}
                        />
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      )
    }
    else if (items) {
      return items.map((itemProps, index) => (
        <Item
          className="select-none"
          key={index}
          style={style}
          {...itemProps}
        />
      ))
    }
    else {
      return itemsNode
    }
  }

  return (
    <Menu as="div" className={rootClassName}>
      <Menu.Button
        className={cx(buttonClassName, 'flex items-center min-w-0', { 'pointer': !disabled })}
        aria-label="Menu"
        disabled={disabled}
      >
        {
          ({ open }) => {
            if (typeof onOpen === 'function') {
              onOpen(open)
            }

            return React.cloneElement(children, { isOpen: open })
          }
        }
      </Menu.Button>
      <Menu.Items className={menuClassName}>
        <ItemsNode />
      </Menu.Items>
    </Menu>
  )
}


export default React.memo(DropdownMenu)
