import React, { useMemo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { useRouteData } from 'models/seo'
import { useModuleTitle } from 'models/settings'

import { Href } from 'components/navigation'
import { Text } from 'components/dataDisplay'


type BreadcrumbsItem = {
  title: string
  to?: string
}

export type BreadcrumbsProps = {
  className?: string
  parent?: BreadcrumbsItem | BreadcrumbsItem[]
  page?: string
  noPageTitle?: boolean
  overflow?: 'visible' | 'hidden'
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { className, parent, page, noPageTitle, overflow = 'hidden' } = props

  const routeData = useRouteData()
  const { title: moduleTitle } = useModuleTitle(routeData?.tag)

  const items = useMemo(() => {
    return [
      {
        title: 'Главная',
        to: '/',
      },
      ...(Array.isArray(parent) ? parent : [ parent ]),
      noPageTitle ? false : {
        title: page || moduleTitle || routeData.title || 'Городские сервисы',
      },
    ].filter(Boolean)
  },[ parent, page, noPageTitle, routeData, moduleTitle ])

  const { isMobile } = useDevice()

  return (
    <nav className={className} aria-label="Breadcrumb">
      <ol>
        {
          items.map(({ title, to }, index) => (
            <li
              key={index}
              className={cx(to ? 'inline-flex' : 'flex')}
              aria-current={to ? null : 'page'}
            >
              {
                to ? (
                  <Href to={to}>
                    <Text
                      className="opacity-32"
                      message={`${title} /&nbsp;`}
                      size={isMobile ? 's13-r' : 't16-20'}
                      color="titanic"
                      html
                    />
                  </Href>
                ) : (
                  <Text
                    className={overflow === 'hidden' ? 'overflow-ellipsis webkit-box' : ''}
                    message={title}
                    size={isMobile ? 'h24' : 'h40'}
                    color="titanic"
                  />
                )
              }
            </li>
          ))
        }
      </ol>
    </nav>
  )
}


export default React.memo(Breadcrumbs)
