import React from 'react'

import { Breadcrumbs, BreadcrumbsProps } from 'components/navigation'
import { Portal } from 'components/layout'


const PortalBreadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { className, page, parent, overflow } = props

  return (
    <Portal id="breadcrumbs">
      <Breadcrumbs
        className={className}
        parent={parent}
        page={page}
        overflow={overflow}
      />
    </Portal>
  )
}


export default PortalBreadcrumbs
