import React, { useCallback } from 'react'
import cx from 'classnames'
import { Menu } from '@headlessui/react'

import { Href } from 'components/navigation'

import { Icon, IconColor, IconName, Text } from 'components/dataDisplay'

import s from './Item.scss'


export type ItemProps = {
  id?: number
  className?: string
  title?: string
  tooltip?: string
  count?: number
  icon?: IconName
  iconColor?: IconColor
  to?: string
  toTab?: string
  loading?: boolean
  disabled?: boolean
  style?: 'leftBorder' | 'hover' | 'blank'
  onPreventClick?: (event?: React.MouseEvent) => void
  onClick?: (event?: React.MouseEvent) => void
}

const Item: React.FC<ItemProps> = (props) => {
  const {
    className, title, count, icon, iconColor, to, toTab, disabled, loading, style = 'hover',
    tooltip, onPreventClick, onClick,
  } = props

  const itemClassName = cx('relative block', s.item, s[style], className)

  const handleClick = useCallback((event) => {
    if (!toTab) {
      event.preventDefault() // toTab link is not working with this
    }
    event.stopPropagation()

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }, [ onClick, toTab ])

  // Prevents menu closing
  const handlePreventClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()

    if (typeof onPreventClick === 'function') {
      onPreventClick(event)
    }
  }, [ onPreventClick ])

  return (
    <Menu.Item
      as={Href}
      className={itemClassName}
      to={to}
      toTab={toTab}
      disabled={disabled}
      onClick={handleClick}
    >
      {({ active }) => {
        const contentClassName = cx('py-12px px-20px w-full whitespace-nowrap text-left flex items-center pointer', {
          [s.active]: active,
          [s.disabled]: disabled || loading,
        })

        return (
          <div
            className={contentClassName}
            onClick={onPreventClick ? handlePreventClick : null}
          >
            {
              icon && (
                <Icon className="mr-12px" name={icon} color={iconColor} />
              )
            }
            {
              Boolean(title) && (
                <Text
                  className={cx(s.text, 'overflow-ellipsis')}
                  size="c16"
                  color="inherit"
                  message={title} title={tooltip || null}
                />
              )
            }
            {
              Boolean(count) && (
                <Text
                  className="pl-4px pb-4px"
                  size="c10"
                  color="rocky"
                  message={`+${count}`}
                />
              )
            }
          </div>
        )
      }}
    </Menu.Item>
  )
}


export default React.memo(Item)
