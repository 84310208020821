import React from 'react'

import { TextColor } from 'components/dataDisplay'

import { TabProps } from '../Tabs'


export type TabsContentProps = {
  className?: string
  id: string
  title: string
  count?: string
  countColor?: TextColor
  borderColor?: 'fargo' | 'rocky'
  disabled?: boolean
  tabNode?: React.FC<TabProps>
}

const Content: React.FC<TabsContentProps> = (props) => {
  const { children } = props

  return (
    <>
      {children}
    </>
  )
}


export default Content
