import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useHistory, usePathname, useSearchParams } from 'router'


type UseQueryTabsProps = {
  subTab?: boolean
  availableTabIds: string[]
  defaultActiveTab?: string
  skip: boolean
}

type Result = [ string, (id: string) => void ]

// Sync tabs state with search params
const useQueryTabs = (props: UseQueryTabsProps): Result => {
  const { subTab, availableTabIds = [], defaultActiveTab, skip } = props

  const history = useHistory()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const searchParamsRef = useRef(searchParams)
  searchParamsRef.current = searchParams

  const queryId = subTab ? 'subTab' : 'tab'

  // tab from the search params
  const tab = searchParams[queryId]

  const activeTab = useMemo(() => {
    if (availableTabIds.includes(tab)) {
      return tab
    }

    if (defaultActiveTab) {
      return defaultActiveTab
    }

    return availableTabIds.length ? availableTabIds[0] : ''
  }, [ tab, availableTabIds, defaultActiveTab ])

  const setActiveTab = useCallback((id: string) => {
    const { subTab, ...newSearchParams } = searchParamsRef.current

    const searchParams = {
      ...newSearchParams,
      [queryId]: id,
    }

    // TODO rework, temp fix for single spa redirect
    if (!searchParamsRef.current.id) {
      history.push(pathname, { searchParams })
    }
    else {
      history.replace(pathname, { searchParams })
    }
  }, [ history, pathname, queryId ])

  // sync 'tag' search param with active tab(add '?tab={activeTab}' to url) on component mount
  useEffect(() => {
    const tab = searchParamsRef.current[queryId]

    if (!tab && !skip) {
      setActiveTab(activeTab)
    }
  }, [ skip, queryId, setActiveTab ])

  return [
    activeTab,
    setActiveTab,
  ]
}


export default useQueryTabs
