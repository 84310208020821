import React from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'

import { TabProps } from '../Tabs'

import s from './Tab.scss'


const Tab: React.FC<TabProps> = (props) => {
  const { id, tagId, title, isActive, borderColor = 'rocky', count, countColor, disabled = false, onClick } = props

  return (
    <>
      <Text
        className={cx(s.tab, s[`border-${borderColor}`],{
          [s.active]: isActive,
          [s.disabled]: disabled,
        })}
        id={tagId}
        message={title}
        size="c16"
        color="titanic"
        tag="button"
        role="tab"
        tabIndex={isActive ? 0 : -1}
        aria-controls={`${tagId}-panel`}
        aria-selected={isActive}
        onClick={!disabled ? () => onClick(id) : null}
      />
      {
        Boolean(count && !disabled) && (
          <Text
            className={cx(s.counter, 'flex flex-col justify-start ml-4px')}
            size="c10"
            color={countColor}
            message={count}
          />
        )
      }
    </>
  )
}


export default React.memo(Tab)
