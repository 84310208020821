import React, { useMemo, useState, useCallback } from 'react'
import { useUniqueId } from 'hooks'
import { useDevice } from 'device'
import cx from 'classnames'

import { WidthContainer } from 'components/layout'
import { TextColor } from 'components/dataDisplay'

import useQueryTabs from './util/useQueryTabs'
import useTabsKeyboard from './util/useTabsKeyboard'

import Tab from './Tab/Tab'
import Content from './Content/Content'
import type { TabsContentProps } from './Content/Content'

import s from './Tabs.scss'


export type TabProps = {
  className?: string
  id: string
  tagId: string
  title: string
  isActive: boolean
  count?: string
  borderColor?: 'fargo' | 'rocky'
  countColor?: TextColor
  disabled?: boolean
  onClick: (id: string) => void
}

export type TabsProps = {
  children: Array<React.ReactElement<TabsContentProps>>
  className?: string
  tabsClassName?: string
  subTab?: boolean
  topNode?: React.ReactNode
  tabListNode?: React.ReactNode
  defaultActiveTab?: string
  withWidthContainer?: boolean
  withQuery?: boolean // sync active tab with query parameter
  onChange?: (id: string) => void
}

type TabsComponent = React.FC<TabsProps> & {
  Content: React.FC<TabsContentProps>
}

const Tabs: TabsComponent = (props) => {
  const {
    children, className, tabsClassName, subTab,
    topNode = null, tabListNode = null, defaultActiveTab, withQuery = false, onChange,
  } = props

  // const { isMobile } = useDevice()
  const tabsId = useUniqueId('tabs')

  // const withWidthContainer = props.withWidthContainer

  const childrenComposed = useMemo(() => children
    .filter(Boolean) // filter falsy values recieved by conditional rendering
    .reduce((result, current) => {
      if (current?.type === React.Fragment && current.props.children) {
        result.push(...current.props.children)
      } else {
        result.push(current)
      }

      return result
    }, [])
  , [ children ])

  const availableTabIds = useMemo(() => (
    React.Children.map(childrenComposed, (child) => child.props.id)
  ), [ childrenComposed ])

  const state = useState(defaultActiveTab || availableTabIds[0])
  const queryState = useQueryTabs({
    subTab,
    availableTabIds,
    defaultActiveTab,
    skip: !withQuery,
  })

  const [ activeTab, setActiveTab ] = withQuery ? queryState : state

  const tabsRef = useTabsKeyboard({ availableTabIds, activeTab, setActiveTab })

  const handleClick = useCallback((id) => {
    if (activeTab === id) {
      return
    }

    setActiveTab(id)

    if (typeof onChange === 'function') {
      onChange(id)
    }
  }, [ activeTab, setActiveTab, onChange ])

  const { isDesktop } = useDevice()


  const tabs = (
    <WidthContainer className={s.tabsContainer} ignore={!props.withWidthContainer}>
      <style>{`.scroll-x::-webkit-scrollbar {display: none}`}</style>
      <div
        ref={tabsRef}
        className={cx(s.tabs, tabsClassName, {
          'scroll-x': !tabListNode || !isDesktop,
        })}
        role="tablist"
      >
        {
          React.Children.map(childrenComposed, (child, index) => {
            const { className, id, title, count, countColor, borderColor, disabled, tabNode } = child.props
            const isActive = id === activeTab

            const tabProps = {
              className,
              key: id,
              id,
              tagId: `${tabsId}-${id}`,
              title,
              isActive,
              count: count,
              countColor,
              borderColor,
              disabled,
              onClick: handleClick,
            }

            return tabNode ? (
              React.createElement(tabNode, tabProps)
            ) : (
              React.createElement(Tab, tabProps)
            )
          })
        }
        {isDesktop && tabListNode}
      </div>
      {!isDesktop && tabListNode}
    </WidthContainer>
  )

  const activeTabIndex = availableTabIds.indexOf(activeTab)
  const activeTabControlId = `${tabsId}-${activeTabIndex}`

  return (
    <div className={className}>
      {tabs}
      {topNode}
      <div
        id={`${tabsId}-${activeTabIndex}-panel`}
        role="tabpanel"
        tabIndex={0}
        aria-labelledby={activeTabControlId}
      >
        {childrenComposed.filter(Boolean)[activeTabIndex]}
      </div>
    </div>
  )
}

Tabs.Content = Content


export default Tabs
